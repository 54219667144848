<template>
  <div class="app-container">
    <el-input v-model.trim="query.accountName" clearable placeholder="根据账户搜索" class="filter-item" style="width: 180px" @keyup.enter.native="toQuery" />
    <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="add">新增账号</el-button>
    <el-button class="filter-item" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="margin-top: 10px;">
      <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
      <el-table-column prop="accountCode" label="账号" />
      <el-table-column prop="accountName" label="账户" />
      <el-table-column prop="bankName" label="银行名称" />
      <el-table-column width="100" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import { del } from "@/api/account";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      formTypes: ["银行卡", "支付宝", "微信"],
      query: {
        accountName: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/account";
      let query = JSON.parse(JSON.stringify(this.query));
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "createAt,desc" },
        query
      );
      return true;
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          console.log(err.response.data.message);
        });
    },
  },
};
</script>